
import Footer from "../../components/footer/footer-component.jsx"



const Home = () => {
  return (
    <section className="bg-gray-100 text-gray-800">
      <div className="container max-w-6xl p-6 mx-auto space-y-6 sm:space-y-12">
        <div className="gap-3 mx-auto max-w-full group hover:no-underline focus:no-underline grid lg:grid-cols-12 bg-white">
          <img src="./images/AURRPC_Logo_Black.jpg" alt="" className="object-cover mx-auto rounded h-60 sm:h-96 lg:col-span-7 bg-gray-500" />
          <div className="p-6 space-y-2 lg:col-span-5 my-auto">
            <h3 className="text-4xl font-semibold sm:text-xl">Adelaide Universities Regiment Rifle and Pistol Club</h3>
          </div>
        </div>
        <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <div className="max-w-sm mx-auto group hover:no-underline focus:no-underline bg-gray-50">
            <img alt="safety" className="object-cover w-full rounded h-44 bg-gray-500" src="./images/Safety-300x148.jpg" />
            <div className="p-6 space-y-2">
              <h3 className="text-2xl font-semibold">SAFETY</h3>
             
              <p>Shooting is an exciting and challenging sport like few others.
              Discipline, practice and training are the only pathways to success in shooting but before all the excitement starts safety must be the first thing you think about.
              Safe gun handling must be practiced to the point where it becomes habit and awareness is your default state of mind.</p>
            </div>
          </div>
          <div className="max-w-sm mx-auto group hover:no-underline focus:no-underline bg-gray-50">
            <img alt="what we do" className="object-cover w-full rounded h-44 bg-gray-500" src="./images/Targets_shot-300x148.gif" />
            <div className="p-6 space-y-2">
              <h3 className="text-2xl font-semibold">WHAT WE DO</h3>
              
              <p>Our Club has a simple mission: to provide a broad range of shooting services to its members.
              With this as our guiding principle you will not be surprised to find we currently offer 13 disciplines held over 14 ranges with more on the way.
              We recently opened a clay target shotgun range and a brand new indoor air pistol hall is not to far away.</p>
            </div>
          </div>
          <div className="max-w-sm mx-auto group hover:no-underline focus:no-underline bg-gray-50">
            <img alt="get involved" className="object-cover w-full rounded h-44 bg-gray-500" src="./images/Training-300x148.jpg" />
            <div className="p-6 space-y-2">
              <h3 className="text-2xl font-semibold">GET INVOLVED</h3>
              
              <p>Let me guess, you have always wanted to take up shooting but have not known where to start? We hear that lot.
              AURRPC has a proud tradition of supporting and training new shooters.
              We have dedicated Instructors and discipline captains to get you started then there is a wealth of experience to help you keep improving year after year.
              Contact us to find out more.</p>
            </div>
          </div>

        </div>

      </div>

      <Footer />
    </section>

 

  );
}
export default Home;



