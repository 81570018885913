import Footer from "../../components/footer/footer-component.jsx"

const Affiliations = () => {
  return (
    <div className="">
      <div className="p-4 lg:p-8 bg-white text-gray-800">
        <div className="container mx-auto space-y-12">
          <h2 className="text-2xl font-bold md:text-4xl">Affiliations</h2>


          <div className="container mx-auto flex flex-col p-6">
            
            <div className="divide-y divide-gray-300">
              <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
                <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                  <img src="./images/affiliate/AASPA-logo.jpg" alt="" className="object-cover mx-auto rounded h-40 lg:col-span-7" />
                </div>

                <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                  <span className="text-xl font-bold md:text-2xl">AASPA</span>
                  <a href="https://www.aaspa.online/" className="text-xs tracking-wider uppercase text-bailey-blue">Australian Army Sports Pistol Association</a>
                  <span className="mt-4 text-gray-700">The objectives of the AASPA are to promote the shooting sports in the Australian Defence Force (ADF) and the ADO, by encouraging regular and reserve forces and civilian APS employees to participate in accordance with the rules of AASPA and affiliated organisations.</span>
                </div>
              </div>
              <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
                <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                  <img src="./images/affiliate/adf-clay-target-logo.jpg" alt="" className="object-cover mx-auto rounded h-40 lg:col-span-7" />
                </div>

                <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                  <span className="text-xl font-bold md:text-2xl">ADF Clay Target Association</span>
                  <a href="https://www.facebook.com/ADFClayTarget/" className="text-xs tracking-wider uppercase text-bailey-blue">Australian Defence Force Clay Target Association</a>
                  <span className="mt-4 text-gray-700">The aim of the ADF Clay Target Association is to encourage and promote Clay Target shooting within the Australian Defence Force.</span>
                </div>
              </div>

              <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
                <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                  <img src="./images/affiliate/IPSCSA-logo.jpg" alt="" className="object-cover mx-auto rounded h-40 lg:col-span-7" />
                </div>

                <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                  <span className="text-xl font-bold md:text-2xl">IPSC SA</span>
                  <a href="https://ipsc.org.au/ipsc-south-australia/" className="text-xs tracking-wider uppercase text-bailey-blue">IPSC South Australia</a>
                  <span className="mt-4 text-gray-700">Since its foundation in 1976, IPSC has spread to over 100 countries including Australia, with SA in particular having 20 affiliated clubs, over 300 elite competitors and many hundreds more competing at club level. SA also had the privilege of hosting the 2016 IPSC National Handgun Championships at Southern Vales Practical Shooting League, which was also the 40th anniversary of IPSC in Australia. It was a record breaking event for an Australian Nationals with over 400 competitors.</span>
                </div>
              </div>

              <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
                <div className="flex items-center justify-center lg:col-span-1 col-span-full">
                  <img src="./images/affiliate/SARPA-logo.png" alt="" className="object-cover mx-auto rounded h-40 lg:col-span-7" />
                </div>

                <div className="flex flex-col justify-center max-w-3xl text-center col-span-full lg:col-span-3 lg:text-left">
                  <span className="text-xl font-bold md:text-2xl">SARPA</span>
                  <a href="https://sarpa.org.au/" className="text-xs tracking-wider uppercase text-bailey-blue">South Australian Revolver and Pistol Association</a>
                  <span className="mt-4 text-gray-700">Incorporated in September 1949, SARPA is the state’s peak body for the sport of target pistol shooting responsible for the growth and development of the safe and challenging sport of Pistol Shooting. We are a member of the national body Pistol Australia (PA) which provides our members with direct access to 15 different matches, including categories for athletes with a disability, that are all graded to suit members of all skill levels from club to the elite. Our link and affiliation with Shooting Australia provides members with the pathway to international competitions and the only shooting body that allows members to be selected for Commonwealth and Olympic Games representation.</span>
                </div>
              </div>

              


            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Affiliations
