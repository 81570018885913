import Footer from "../../components/footer/footer-component.jsx"

const Collectors = () => {
  return (
    <div className="">
      <div className="p-4 lg:p-8 bg-white text-gray-800">
        <div className="container mx-auto space-y-12">
          <h2 className="text-2xl font-bold md:text-4xl">Frequently Asked Questions</h2>

          <section className="bg-gray-100 text-gray-800">
            <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
              <div className="grid gap-10 md:gap-8 sm:p-3 md:grid-cols-2 lg:px-12 xl:px-32">
                <div>
                  <h3 className="font-semibold">Who do I contact to ask more questions?</h3>
                  <p className="mt-1 text-gray-600">Membership Secretary
                    If you have more questions or need any clarification please contact us via the following email.
                  </p>
                  <p><a href="mailto:membership@aurrpc.com.au">membership@aurrpc.com.au</a></p>


                </div>
                <div>
                  <h3 className="font-semibold">How long does it take to get your licence?.</h3>
                  <p className="mt-1 text-gray-600">
                    As you can imagine there are a few hoops to jump through to obtain your firearms license.
                    On average it can take 4 to 6 months including applications, police checks and training.

                    If you would like to talk to someone about getting the process going and whats involved then we have someone who’s job is to help you through the process.
                    Send us an email and lets get the ball rolling.
                  </p>
                  <p><a href="mailto:membership@aurrpc.com.au">membership@aurrpc.com.au</a></p>

                  <p>Have you checked out the membership page?</p>
                </div>
                <div>
                  <h3 className="font-semibold">Where can I download the Club calendar?</h3>
                  <p className="mt-1 text-gray-600">
                    If  you would like a PDF of the calendar to stick on your wall then click below for Part 1 (Part 2 coming soon)</p>
                  <p>
                    <a href="./downloads/AURRPC-Calendar.pdf">AURRPC Calendar - Part 1</a>
                  </p>

                </div>
                <div>
                  <h3 className="font-semibold">Where can I download a Range Map?</h3>
                  <p className="mt-1 text-gray-600">
                    If  you would like a PDF of the Range Map click here.
                    <a href="./downloads/AURRPC-RangeMap.pdf">Range Map</a>
                  </p><p>The range address can be seen on our Contact Us page.</p>
                </div>
                <div>
                  <h3 className="font-semibold">I found an error on your site. How do I report it?</h3>
                  <p className="mt-1 text-gray-600">Oops.
                    That would be me, sorry and thanks for taking the time to let me know.

                  </p><p><a href="mailto:it@aurrpc.com.au">it@aurrpc.com.au</a></p>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Collectors;
