import Footer from "../../components/footer/footer-component.jsx"

const Membership = () => {
  return (
    <div className="">
      <div className="p-4 lg:p-8 bg-white text-gray-800">
        <div className="container mx-auto space-y-12">
          <h2 className="text-2xl font-bold md:text-4xl">Membership</h2>

          <div className="grid gap-4 mx-4 sm:grid-cols-12">
            <div className="col-span-12 sm:col-span-3">
              <div className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-bailey-blue">
                <h3 className="text-3xl font-semibold mb-2">Membership Forms</h3>
                <span className="text-sm uppercase text-gray-600"><a href="./downloads/20230922_Membership_Application_Form.pdf">Membership Application Form 2023</a></span><br />
                <span className="text-sm uppercase text-gray-600"><a href="./downloads/Membership-Renewal-Form.xlsx">Membership Renewal Form 2023</a></span><br />
                <span className="text-sm uppercase text-gray-600"><a href="./downloads/MembershipYouthSponsor.pdf">Membership Youth Sponsor Form</a></span><br />
                <span className="text-sm uppercase text-gray-600"><a href="./downloads/New-Member-Referee-Form-Rev-4.pdf">New Member Referee Form</a></span><br />
              </div>
              <div className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-bailey-blue">
                <h3 className="text-3xl font-semibold mb-2">Other Forms</h3>
                <span className="text-sm uppercase text-gray-600"><a href="./downloads/Visitor-Attendance-Form.pdf">Visitor Attendance Form</a></span><br />
                <span className="text-sm uppercase text-gray-600"><a href="./downloads/Handgun-Attendance-Record-Members.pdf">Handgun Attendance Record - Members</a></span><br />
                <span className="text-sm uppercase text-gray-600"><a href="./downloads/AURRPC-Competition-Score-Sheet-Rev-3.pdf">Competition Score Sheet</a></span><br />
                <span className="text-sm uppercase text-gray-600"><a href="./downloads/AURRPC-Members-Log-Sheet.pdf">Members Log Sheet</a></span><br />
              </div>
            </div>
            <div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
              <div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-300">
                <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-bailey-blue">
                <h3 className="text-xl font-semibold tracking-wide">Contact us via email</h3>
                <span className="text-xs font-semibold tracking-wide uppercase text-gray-600">STEP 1</span> 
                  <p className="mt-3">Contact us via email and we will get back to you with more information.</p>
                  <p><a href="mailto:membership@aurrpc.com.au">membership@aurrpc.com.au</a></p>
                </div>
                <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-bailey-blue">
                  <h3 className="text-xl font-semibold tracking-wide">Arrange a club visit</h3>
                  <span className="text-xs font-semibold tracking-wide uppercase text-gray-600">STEP 2</span>
                  <p className="mt-3">Once we have made contact we will invite you to a monthly Club Committee meeting where we will ask you to introduce yourself.</p>
                </div>
                <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-bailey-blue">
                  <h3 className="text-xl font-semibold tracking-wide">Progress Membership</h3>
                  <span className="text-xs font-semibold tracking-wide uppercase text-gray-600">STEP 3</span>
                  <p className="mt-3">Following your attendance at a Club Meeting, we may look to progress your membership. How long this takes will depend on a number of factors including licencing, reference checks and training'</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Membership;
