

const Footer = () => {
    return (
        <section id="footer" className="min-w-screen max-w-screen  p-10  bg-gray-900 ">
           
        </section>
    );
  }
  
  export default Footer;