

const ContactUs = () => {
  
  return (
    <div className="min-h-screen">
      <div className="p-4 lg:p-8 bg-white text-gray-900">
        <div className="container mx-auto space-y-12 flex sm:flex-nowrap flex-wrap">
         

      
        <div className="lg:w-2/3 md:w-1/2 bg-white rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <iframe
            width="100%"
            height="100%"
            title="map"
            className="absolute inset-0"
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}
            style={{ filter: "opacity(0.7)" }}
            src="https://www.google.com/maps/embed/v1/place?q=1+Range+Road+N,+Lower+Hermitage+SA,+Australia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          />
        </div>

        <div className="xl:w-1/3 sm:w-1/2 md:w-1/2 flex flex-col mx-auto w-full md:py-8 mt-8 md:mt-0">
        <h2 className="text-2xl font-bold md:text-4xl mb-10">Contact Us</h2>
          <p className="leading-relaxed mb-5 text-gray-900">
            Reach out for a chat. We would love to hear from you.
          </p>
          <p><a className="text-gray-900" href="mailto:membership@aurrpc.com.au">membership@aurrpc.com.au</a></p>
        </div>

        </div>
      </div>
    </div>
  );
}
export default ContactUs
