import Footer from "../../components/footer/footer-component.jsx"


const EventCalendar = () => {
  return (
    <div className="">
    <div className="p-4 lg:p-8 bg-white text-gray-800">
      <div className="container mx-auto space-y-12">
        <h2 className="text-2xl font-bold md:text-4xl">Perpetual Calendar</h2>


        <div className="container mx-auto flex flex-col">
          
          <div className="divide-y divide-gray-300">


            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
                <span className="text-xl font-bold md:text-2xl mb-2">1st. SATURDAY</span>
                <span className="mt-1  text-gray-700">Shotgun Clay 9:00am</span>
                <span className="mt-1 text-gray-700">Service Pistol 11:30am</span>
                <span className="mt-1 text-gray-700">Metallic Silhouette 12:30pm</span>
               </div>

              <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
                <span className="text-xl font-bold md:text-2xl">1st. THURSDAY</span>
                <span className="mt-2 text-gray-700">Steel Action 9:30am</span>
              </div>
            </div>

            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
                <span className="text-xl font-bold md:text-2xl mb-2">2nd. SATURDAY</span>
                <span className="mt-1  text-gray-700">Steel Action 9:00am</span>
                <span className="mt-1 text-gray-700">Service Pistol (25 yard) 11:30am</span>
                <span className="mt-1 text-gray-700">Single Six 1:30pm</span>
                <span className="mt-1 text-gray-700">Shotgun Static 1:30pm</span>
               </div>

              <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
                <span className="text-xl font-bold md:text-2xl">2nd. THURSDAY</span>
                <span className="mt-2 text-gray-700">Service Pistol 10:00am</span>
              </div>
            </div>
            
           
            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
              <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
                <span className="text-xl font-bold md:text-2xl mb-2">3rd. SATURDAY</span>
                <span className="mt-1 text-gray-700">Air Pistol 12 noon</span>
                <span className="mt-1  text-gray-700">IPSC 12:30pm</span>
                
               </div>

              <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
                <span className="text-xl font-bold md:text-2xl">3rd. THURSDAY</span>
                <span className="mt-2 text-gray-700">ISSF Center Fire 10:00am</span>
              </div>
            </div>

            <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
            <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
              <span className="text-xl font-bold md:text-2xl mb-2">4th. SATURDAY</span>
              <span className="mt-1 text-gray-700">ISSF (.22) 10:30am</span>
              <span className="mt-1 text-gray-700">Rifle 12 noon</span>
              <span className="mt-1  text-gray-700">Black Powder 1:30pm</span>
              
              
             </div>

            <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
              <span className="text-xl font-bold md:text-2xl">4th. THURSDAY</span>
              <span className="mt-2 text-gray-700">ISSF (.22) 10:00am</span>
            </div>
          </div>

          <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
          <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
            <span className="text-xl font-bold md:text-2xl mb-2">Club Meetings</span>
            <span className="mt-1  text-gray-700">2nd. Wednesday of each month 7:30pm</span>
            <span className="mt-1 text-gray-700">AGM 2nd. Wednesday of May 7:30pm</span>
           </div>

          <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
            <span className="text-xl font-bold md:text-2xl">Come & Try</span>
            <span className="mt-2 text-gray-700">TBA 9:00am</span>
          </div>
        </div>
            
        <div className="grid justify-center grid-cols-4 p-8 mx-auto space-y-8 lg:space-y-0">
          <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
            <span className="text-xl font-bold md:text-2xl mb-2">Collectors Shoot</span>
            <span className="mt-1  text-gray-700">TBA on a 5th Saturday of the month 1:00pm</span>
           </div>

          <div className="flex flex-col text-center max-w-3xl col-span-full lg:col-span-2">
            <span className="text-xl font-bold md:text-2xl"></span>
            <span className="mt-2 text-gray-700"></span>
          </div>
        </div>

          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}
export default EventCalendar;
