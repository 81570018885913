import Footer from "../../components/footer/footer-component.jsx"

const Collectors = () => {
  return (
    <div className="">
      <div className="p-4 lg:p-8 bg-white text-gray-800">
        <div className="container mx-auto space-y-12">
          <h2 className="text-2xl font-bold md:text-4xl">El Alamein Collectors</h2>
          

          <div className="flex flex-col overflow-hidden rounded-md shadow-sm">

            <img src="./images/CollectionBanner-300x82.jpg" alt="" className="my-5 max-w-lg h-82 bg-gray-500" />

            <p className="mt-2">
            The El Alamein Collectors Club started almost 20 years ago with the principal function of guiding members through the process of obtaining a collectors licence.  El Alamein meets once a month at the Navy, Military and Air Force Club where members explore and discuss the amazing world of antique and unusual firearms.
            </p>
            <p className="mt-2">
            I know it is hard to believe but not all firearms are bought for target practice.  Many are collected for investment, some for educational reasons and many for the sheer joy of owning a unique piece of history.  They are collected by enthusiasts, historians, military historiographers, teachers, engineers and some as family heirlooms.
            </p>
            <p className="mt-2"p>
            A collectors license, as apposed to a general firearms licence, does not give you the right to fire your collections whenever you like.  It does however allow non-shooters to collect and own firearms they are interested in for many and varied reasons.  Many collectors own firearms unavailable under a standard license and in some cases they own pieces that are heavily restricted for any other reason.   Like a normal firearms licence though you must be a member of a club.
            </p><p className="mt-2">
            Although collectors cannot use their collectibles regularly they are allowed to function test them once a year to make sure they are in good working order.  AURRPC opens its ranges to the members of El Alamein on request a couple of times a year for this purpose.  On these special days you will often find enthusiastic members from both clubs huddled around a unique piece of history discussing it at length.</p>
          </div>
          
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Collectors;
