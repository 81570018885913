
import { Routes, Route } from 'react-router-dom';
import Home from './routes/home/home.jsx';
import Navigation from './routes/navigation/navigation.jsx';
import Collectors from './routes/collectors/collectors.jsx';
import Diciplines from './routes/diciplines/diciplines.jsx';
import Affiliations from './routes/affiliations/affiliations.jsx';
import Membership from './routes/membership/membership.jsx';
import EventCalendar from './routes/event-calendar/event-calendar.jsx';
import FAQ from './routes/faq/faq.jsx';
import ContactUs from './routes/contact-us/contact-us.jsx';



const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path='diciplines' element={<Diciplines />} />
        <Route path='affiliations' element={<Affiliations />} />
        <Route path='collectors' element={<Collectors />} />
        <Route path='membership' element={<Membership />} />
        <Route path='calendar' element={<EventCalendar />} />
        <Route path='faq' element={<FAQ />} />
        <Route path='contact-us' element={<ContactUs />} />
      </Route>
    </Routes>
  );
}

export default App;
