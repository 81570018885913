import Footer from "../../components/footer/footer-component.jsx"

const Diciplines = () => {
  return (
    <div className="">
      <div className="p-4 lg:p-8 bg-gray-100 text-gray-800">
     
        <div className="container mx-auto space-y-12">
        <h2 className="text-2xl font-bold md:text-4xl">Diciplines</h2>

          <div className="grid grid-rows-6 sm:grid-cols-1 md:grid-cols-2 gap-4 mx-auto">

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/AirPistolMan-300x200.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className=" p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">AIR PISTOL</h3>
              <p className="my-4 text-gray-600">Air Pistol and Air Rifle competitions are some of the fasting growing shooting disciplines in the world.
                It’s quiet, clean, there is no recoil, it’s shot indoors with very light equipment and the ammunition is very cheap.
                It is easily shot by all ages, levels of experience and physical abilities so it is not hard to see why it is shot by entire families all around the word.
                Once you have achieved the Zen like state needed to place a 4.5mm projectile in the 11.5 cm bulls eye at the center of a 17 cm square target from 10 meters away, you can lower your arm, relax and take a breath.  The problem is, to reach the top of the podium at the Olympics you must be able to do that 59 more times in 75 minutes.
              </p>
            </div>
          </div>

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/black-powder-dumping-1-300x204.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">BLACK POWDER</h3>

              <p className="my-4 text-gray-600">There is only one reason to shoot Black Powder.  It’s really GREAT FUN!   You’ll love the boom, the smoke, and the smell!
                Add  to this the sense of  history  you experience as the long arm or pistol thumps your cast lead ball bulls-eye bound.
                We all love movies and books showing – Pirates with  flintlocks, Bush rangers with cap ‘n ball revolvers, Civil War warriors using muzzle and breech loading rifles, and Gentlemen afield with the old fowling pieces! It can be quite addictive researching these arms of yesterday, let alone possessing them and shooting them! Why not ‘have a go’? We enthusiasts meet each month to safely shoot, learn, admire firearms, swap experiences and anecdotes relating to ”Shooting the Black”.
                This is done in a relaxed friendly atmosphere while safely and gently competing in appropriately designed matches on 25 or 100 yd ranges.</p>

            </div>
          </div>

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/centerfire-300x200.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">CENTER FIRE</h3>

              <p className="my-4 text-gray-600">Like its Air pistol cousin, Center Fire pistol is a game of pure accuracy delivered through concentration, constant practice and self improvement.
                Center Fire and Air Pistol competitions come under the governance of the International Sports Shooting Federation.
                ISSF 25 meter pistol is shot with a .22-caliber sports pistol and all firing must be done with one hand, unsupported.  If that’s not enough of a challenge then 50 free pistol will test your limits.
                It’s an economical shooting event that is accessible and attractive to all shooters young and old, new and experienced.
                Our shoots are held on the fourth Saturday each month in a friendly, relaxed atmosphere and new shooters are always welcome.
                For those who excel, it provides a route to shooting at national level, and even at Commonwealth and Olympic Games.</p>

            </div>
          </div>

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/IPSC1-300x201.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">IPSC (Handgun)</h3>

              <p className="my-4 text-gray-600">So you think you’re a pretty good shot?  Plinked the odd can off logs at granddads farm did you?
                What about hitting 16 partially hidden 35×15 cm bulls-eyes with two clean shots at 20 meters while traversing a maze?
                Or hitting 9 10×2.5 cm good shots between gaps in a wall while running flat out?
                Ever tried hitting a group of 10 cm steel plates 15 meters away around a corner while standing on a wobbly bridge?
                Then just to make it challenging, you do all of this while racing the clock.
                Oh, I forgot, some of the targets will be moving! Once you have mastered the club competitions you can join us to compete against other clubs, states, regions and eventually other countries at one of the worlds biggest shooting competitions. I bet you never did that on Granddads farm</p>

            </div>
          </div>

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/trapshooter5-300x200.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">CLAY TARGET</h3>

              <p className="my-4 text-gray-600">PULL! You hear a click, then before your eyes bright orange clay targets start flying away from you in every direction.
                All you have to do is knock them out of the sky before they sail off into the tree line.
                The wind makes them bounce around, the throwers change the angle, speed and direction without warning and the person who went before you made it look oh so easy.
                It is a challenge but one that is a lot of fun on the long road to mastering.
                Once you have finally mastered the art of knocking the little orange devils out of the sky everything from club and inter-club competitions to Olympic gold awaits you.
                Or, if you are like me, you hit the traps every 4th Saturday of the month just for the joy of the sport and for another dose of the jeering support of your mates</p>

            </div>
          </div>


          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/shotgun_competition_low_port_kneeling.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">IPSC (Shotgun)</h3>

              <p className="my-4 text-gray-600">IPSC stands for the International Practical Shooting Confederation.
                The word to really take note of in the title is “Practical”.  In this challenge you won’t have a moment to think once the timer sets you off.
                Accuracy is a good start but it is only half the game here.
                Yes you have to hit the targets which could be static metal plates, moving clay targets or cans that pop up out of nowhere when you hit a see-saw, but you will have to do all this from a variety of shooting positions.
                Then you will have to figure out the best time to reload, do that on the move, then make sure you re-position correctly for the best chance at the next string of targets.
                Making sure you have the right type of shot for the right targets in the right order on your belt is a puzzle that starts messing with your head even before your turn begins!</p>

            </div>
          </div>

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/MetalicSilhouetteTargets-300x206.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">METALLIC SILHOUETTE (Handgun)</h3>

              <p className="my-4 text-gray-600">Starting in Mexico in the early 1900’s “Siluetas Metalicas” was a simulated hunting competition.
                At AURRPC we shoot a modified course of 20 targets over 100 meters.
                Metal targets start with 28cm (h) chickens at 25 meters, 36cm (h) pigs at 50 meters, 58cm (h) turkeys at 75 meters and finally 69 cm (h) Rams way out at 100 meters.
                You can use any handgun at our club competitions, but Metallic Silhouette is truly the home of big caliber metal.
                You will find 44 magnums, 45 colts and even the odd 308 Thompson Contender on the line on any given Saturday.
                If you want to hone your long distance handgun skills then this is sport is for you.
                A friendly warning though, these targets may be made from 1cm thick Bisalloy 500 steel but by the end of the day you will swear they can duck! </p>

            </div>
          </div>


          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/Rifle1-300x200.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">RIFLE</h3>

              <p className="my-4 text-gray-600">Rifle or long arm shooting has a discipline to suit every type of rifle and every type of personal interest.
                You can compete in the IPSC style practical shoots with everything from rimfire .22’s up to the big bore 308’s.
                Here at AURRPC we have a 100 meter range that is perfect for small to medium bore active bench rest completions which are held once a month or for a casual afternoon sighting in your trusty old 303.
                What we find most people come for though is the endless supply of very experienced shooters who are more than happy to answer all the questions you have when you’re when starting out.
                Some of them even get the answers right from time to time. </p>

            </div>
          </div>

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/service5.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">SERVICE PISTOL</h3>

              <p className="my-4 text-gray-600">For a few hundred dollars you can get yourself a great quality 6 round revolver, $40 dollars of 38 Special ammo and you are ready to go.
                This makes Service Pistol one of cheapest and most exciting disciplines to get started in.  It is also a great way to learn the basics of handgun safety and precision.
                Your first serial is shot at 50 meters then your targets move in to 25, 10 and finally 7 yards.  Don’t get excited though, it doesn’t get any easier.
                The closer they get the less time you have to engage them.  You engage your targets left and right handed, prone (lying down), sitting, around a barricade and in multiple different combinations.
                At the 7 yard mark you have 15 seconds to shoot 6 shots on two targets and that includes the reload which isn’t even the hard bit! In this string your not allowed to aim!  </p>

            </div>
          </div>

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/sheriff_shutterstock_96392837-1984x1314-1.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">SINGLE SIX</h3>

              <p className="my-4 text-gray-600">Do you rue not being born in the days of Wyatt Earp and Billy the Kid?
                Does the theme song to The Good, The Bad and The Ugly still stir something deep inside?  Does it irk you when someone takes great delight in reminding you that John Wayne’s real name was Marion?
                If the answer to any of these questions was yes then Single Six competitions are for you.
                Shot at both metal plates and paper targets, against the clock to test speed or as a pure accuracy shoot this is a couple of hours once a month bathed in history.
                Although most of the firearms used are not from the 1800’s they look feel and function just like they did back in the day.
                Trying to reload a 357 magnum as quickly as you can when the cylinder doesn’t open can really teach you about managing your stress levels.
                n the other side of the coin fanning the hammer and hearing 6 distinct strikes on a steel target can lift the spirits just as quickly </p>

            </div>
          </div>

          <div className="overflow-hidden rounded-md shadow-sm">
            <img src="./images/SteelActionTargets-300x225.jpg" alt="" className="h-80 bg-gray-500 aspect-video" />
            <div className="p-6 bg-gray-50">

              <h3 className="text-2xl font-bold">STEEL ACTION</h3>

              <p className="my-4 text-gray-600">Fast is only the starting gear for this discipline.  If you want to get on the podium then really, really fast is where you need to be.  5 targets, 5 shots and no time to waste.
                The world champs do some of these courses in less than 2 seconds.  Mere mortals like us dream of getting the job done in less than 4… maybe… one day.  Blink and you will miss the round.
                Sneeze with your eyes closed and you could miss the whole competition!
                Shot with everything from ultra light, purpose built .22 caliber pistols adorned with laser sights, through to the most space aged looking gas leveling, red dot scoped 38’s and just about everything in between.
                Shot from a single position makes this sport accessible by all ages, physiques and all skill levels.
                Steel Action is an exiting quick draw shoot that thrills the audience every bit as much as the competitors.  </p>

            </div>
          </div>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
}
export default Diciplines;
