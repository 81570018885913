import {Fragment} from 'react';
import { Outlet, Link } from 'react-router-dom';


<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
</svg>



const Navigation = () => {
return (
    <Fragment>
        <div className="relative md:sticky md:top-0 z-10 w-full flex flex-wrap items-center justify-between py-4 bg-gray-800 ">
            <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
                <Link className="flex items-center text-xl text-gray-100 hover:text-gray-100 focus:text-gray-100  lg:mt-0 mr-1" to='/'>
                   <h2>AURRPC</h2>
                </Link>

                <div className=" flex-grow items-center">


                    <ul className="flex flex-col sm:flex-row pl-0 list-style-none mr-auto">
                        <li className="nav-item p-2">
                            <Link className=" text-gray-400 hover:text-white focus:text-white p-0" to='/diciplines'>Diciplines</Link>
                        </li>
                        <li className="nav-item p-2">
                            <Link className=" text-gray-400 hover:text-white focus:text-white p-0" to='/affiliations'>Affiliations</Link>
                        </li>
                        <li className="nav-item p-2">
                            <Link className=" text-gray-400 hover:text-white focus:text-white p-0" to='/collectors'>Collectors</Link>
                        </li>
                        <li className="nav-item p-2">
                            <Link className=" text-gray-400 hover:text-white focus:text-white p-0" to='/membership'>Membership</Link>
                        </li>
                        <li className="nav-item p-2">
                            <Link className=" text-gray-400 hover:text-white focus:text-white p-0" to='/calendar'>Calendar</Link>
                        </li>
                        <li className="nav-item p-2">
                            <span className=" text-gray-400 hover:text-white focus:text-white p-0"><a href="./downloads/AURRPC_Merch.pdf">Merchandise</a></span>
                        </li>
                        <li className="nav-item p-2">
                            <Link className=" text-gray-400 hover:text-white focus:text-white p-0" to='/faq'>FAQ</Link>
                        </li>
                        <li className="nav-item p-2">
                        <Link to='/contact-us' className=" text-gray-400 hover:text-white focus:text-white p-0">Contact Us</Link>
                        </li>
                    </ul>

                </div>

            </div>
        </div>
        <Outlet />
    </Fragment>

);

}
export default Navigation

